.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px; // Adjust height as needed
    background-color: #ffffff; // Set your desired background color
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Adds a subtle shadow for effect
    padding: 10px;

    @media (max-width: 768px) {
      
        height: 40px;
    }

    .logo-container {
        display: flex;
        justify-content: left;
        align-items: center;

        .logo {
            height: 70px; // Set the logo size
            width: auto; // Maintain aspect ratio
            
            @media (max-width: 768px) {
                width: 150px;
                height: 40px;
            }
        }

        .playStore {
            height: 60px; // Set the logo size
            width: auto; // Maintain aspect ratio
            margin-top: 10px;

            @media (max-width: 768px) {
                width: 100px;
                height: 40px;
            }
        }
    }
}

/* Mobile view adjustments */
