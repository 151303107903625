.home-container {
    display: flex;
    flex-direction: column;
    // padding: 15px;
    max-width: 1200px;
    margin: 0 auto;
  
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
    }
  
    .calculator-container {
    //   width: 100%;
      margin-bottom: 20px;
      background-color: #f0f8ff;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
  
      @media (min-width: 768px) {
        width: 40%;
        margin-right: 20px;
        margin-bottom: 0;
        padding: 20px;
      }
  
      h1 {
        text-align: center;
        color: #fff;
        font-size: 1.5rem;
        margin-bottom: 15px;
        background-color: #124197;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        @media (min-width: 768px) {
          font-size: 2rem;
          padding: 10px;
        }
      }
  
      .calci {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        @media (min-width: 768px) {
          padding: 20px;
        }
  
        .currency-select {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 15px;
  
          @media (min-width: 768px) {
            flex-direction: row;
            justify-content: center;
            margin-bottom: 20px;
          }
  
          label {
            font-size: 1.1rem;
            margin-bottom: 5px;
  
            @media (min-width: 768px) {
              font-size: 1.2rem;
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
  
          .sl {
            width: 100%;
            max-width: 300px;
            padding: 8px;
            font-size: 1rem;
            border: 1px solid #ccc;
            border-radius: 5px;
  
            @media (min-width: 768px) {
              width: auto;
            }
          }
        }
  
        .checkbox-group {
          display: flex;
          justify-content: center;
          gap: 10px;
          margin-bottom: 15px;
          flex-wrap: wrap;
  
          @media (min-width: 768px) {
            gap: 15px;
            margin-bottom: 20px;
          }
  
          label {
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #333;
          }
  
          input[type="checkbox"] {
            margin-right: 5px;
            width: 16px;
            height: 16px;
            accent-color: #007bff;
          }
        }
      }
    }
  
    .tips-container {
    //   width: 100%;
      background-color: #f0f8ff;
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: justify;
      @media (min-width: 768px) {
        width: 60%;
        padding: 20px;
      }
  
      h2 {
        font-size: 1.3rem;
        margin-bottom: 12px;
  
        @media (min-width: 768px) {
          font-size: 1.5rem;
          margin-bottom: 15px;
        }
      }
  
      ul {
        padding-left: 15px;
  
        @media (min-width: 768px) {
          padding-left: 20px;
        }
  
        li {
          margin-bottom: 8px;
          color: #777373;
          font-size: 0.9rem;
  
          @media (min-width: 768px) {
            margin-bottom: 10px;
            font-size: 1rem;
          }
        }
      }
  
      p {
        font-size: 0.9rem;
        margin-bottom: 12px;
        color: #777373;
  
        @media (min-width: 768px) {
          font-size: 1rem;
          margin-bottom: 15px;
        }
      }
    }
  
    .calculations {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 15px;
  text-align: left;
      table {
        min-width: 100%;
        border-collapse: collapse;
      }
  
      thead th {
        background-color: #f4f4f4;
        padding: 8px;
        font-size: 0.9rem;
  
        @media (min-width: 768px) {
          padding: 10px;
          font-size: 1rem;
        }
      }
  
      tbody td {
        padding: 8px;
        font-size: 0.9rem;
        white-space: nowrap;
  
        @media (min-width: 768px) {
          padding: 10px;
          font-size: 1rem;
        }
      }
  
      .small-input {
        width: 50px;
        padding: 4px;
        font-size: 0.9rem;
  
        @media (min-width: 768px) {
          width: 60px;
          padding: 5px;
        }
      }
    }
  
    .total {
      margin: 15px 0;
      text-align: center;
  
      p {
        font-size: 1.1rem;
        margin-bottom: 5px;
  
        @media (min-width: 768px) {
          font-size: 1.2rem;
        }
      }
  
      i {
        font-size: 0.9rem;
        color: red;
  
        @media (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  
  .print-button {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 15px auto;
    padding: 8px 15px;
    background-color: #124197;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  
    &:hover {
      background-color: #0d3172;
    }
  
    @media (min-width: 768px) {
      padding: 10px 20px;
    }
  }
  
  @media print {
    .home-container {
      display: block;
      padding: 0;
    }
  
    .calculator-container {
      width: 100% !important;
      margin: 0 !important;
      box-shadow: none !important;
    }
  
    .tips-container,
    .header,
    .footer,
    .print-button {
      display: none !important;
    }
  }
  